import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'canonicalCollectionSlug', pure: true,
    standalone: true
})
export class CanonicalCollectionSlugPipe implements PipeTransform {
    private readonly exceptions = [
        'system-3'
    ];

    transform(slug: string): string {
        if (this.exceptions.includes(slug)) {
            return slug;
        }
        return slug.replace(/([a-z]+)-[\d]+$/, '$1');
    }
}
