import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { JsonLd } from '../../../catalog/providers/json-ld.service';

@Component({
    selector: 'kb-json-ld',
    templateUrl: './json-ld.component.html',
    styleUrls: ['./json-ld.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class JsonLdComponent {
    @Input()
    set json(currentValue: JsonLd | undefined) {
        this.jsonLD = this.getSafeHTML(currentValue);
    }
    @HostBinding('innerHTML') jsonLD?: SafeHtml;

    constructor(private sanitizer: DomSanitizer) {}

    getSafeHTML(value: JsonLd | undefined) {
        if (!value) {
            return this.sanitizer.bypassSecurityTrustHtml('');
        }
        if (!value['@context']) {
            value['@context'] = 'http://schema.org';
        }
        const json = value ? JSON.stringify(value, null, 2).replace(/<\/script>/g, '<\\/script>') : '';
        const html = `<script type="application/ld+json">${json}</script>`;
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
}
