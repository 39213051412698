import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Event, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { DataService } from '../data/data.service';
import { graphql } from '../../../common/gql';
import { GetProductDetailQuery } from '../../../catalog/common/gql/graphql';
import { ProductPreviewFragment } from '../../../common/generated-types';
import { ActiveService } from '../active-order/active.service';
import { CartFragment } from '../../../common/gql/graphql';

type DmptProductData = {
    product_name: string;
    product_url: string;
    product_image_path: string;
    product_description?: string;
    product_sku: string;
    product_price: string;
    product_status: string;
    product_currency: string;
    product_categories: string;
    product_brand: string;
};

type DmptFunction = {
    (type: 'track'): void;
    (type: 'track', productData: DmptProductData): void;
    (type: 'identify', email: string): void;
    (type: 'cartInsight', cartData: DmptCart): void;
};

interface DmptLineItem {
    sku: string;
    name: string;
    description: string;
    category: string;
    other: { [key: string]: any };
    unitPrice: number;
    salePrice: number;
    quantity: number;
    totalPrice: number;
    imageUrl: string;
    productUrl: string;
}

interface DmptCart {
    programID: number;
    /**
     * @description
     * This is the time, in minutes, we wait before sending out the email
     */
    cartDelay: number;
    /**
     * @description
     * The ID of your shopping cart. This should be unique.
     */
    cartID: string;
    /**
     * @description
     * The state in which the cart is in. It must be set to ORDER_COMPLETE once the order has been completed. This will prevent an abandoned cart email being sent.
     */
    cartPhase: 'ORDER_COMPLETE' | 'ORDER_PENDING';
    currency: string;
    subtotal: number;
    discountAmount: number;
    taxAmount: number;
    grandTotal: number;
    cartUrl: string;
    lineItems: DmptLineItem[];
}

declare global {
    interface Window {
        dmPt?: DmptFunction;
    }
}

export const GetActiveCustomerEmailDocument = graphql(`
    query GetActiveCustomerEmail {
        activeCustomer {
            id
            emailAddress
        }
        activeOrder {
            customer {
                id
                emailAddress
            }
        }
    }
`);

function formatMoney(input: number) {
    return +(input / 100).toFixed(2);
}

/**
 * API documentation: https://developer.dotdigital.com/
 * Web behaviour tracking scripts: https://support.dotdigital.com/en/articles/8199234-install-web-behavior-tracking > for tracking users across your site, abandoned browse programs
 * Abandoned cart json breakdown: https://support.dotdigital.com/en/articles/8199252-abandoned-cart-json-breakdown > for abandoned cart programs
 * Order insight json breadown: https://developer.dotdigital.com/reference/insight-data#order-insight-data-schema > for order data
 * Product insight json breakdown: https://developer.dotdigital.com/reference/insight-data#product-insight-data-schema > for product and catalogue data
 */
@Injectable({
    providedIn: 'root',
})
export class DotdigitalService {
    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private router: Router,
        private dataService: DataService,
        private activeService: ActiveService,
    ) {}

    setUpTracking() {
        if (isPlatformBrowser(this.platformId)) {
            this.router.events
                .pipe(filter((e: Event): e is NavigationEnd => e instanceof NavigationEnd))
                .subscribe(() => {
                    this.trackPageView();
                });

            this.dataService
                .query(GetActiveCustomerEmailDocument, {}, { fetchPolicy: 'cache-only' })
                .subscribe((result) => {
                    this.identifyCustomer(
                        result.activeCustomer?.emailAddress ?? result.activeOrder?.customer?.emailAddress,
                    );
                });

            this.activeService.activeOrder$.subscribe((order) => {
                this.trackActiveOrder(order);
            });
        }
    }

    public trackPageView() {
        if (isPlatformBrowser(this.platformId) && environment.production) {
            window.dmPt?.('track');
        }
    }

    public trackViewedProduct(
        product: NonNullable<GetProductDetailQuery['product']>,
        productPreview: ProductPreviewFragment,
    ) {
        if (isPlatformBrowser(this.platformId) && environment.production) {
            const cheapestVariantPrice = product.variants.map((v) => v.priceWithTax).sort((a, b) => a - b)[0];
            const hasStock = product.variants.some((v) => v.stockLevel === 'IN_STOCK');
            const categoryFacetValues = product.facetValues
                .filter((fv) => ['category', 'technique', 'medium', 'brand'].includes(fv.facet.code))
                .map((fv) => `${fv.facet.code}_${fv.code}`);
            const brand = product.facetValues.find((fv) => fv.facet.code === 'brand')?.name;
            const viewedProduct = {
                product_name: productPreview.name,
                product_url: `https://www.artsupplies.co.uk/p/${productPreview.slug}`,
                product_image_path: product.featuredAsset?.preview ?? '',
                // product_description: productPreview.description,
                product_sku: this.productSku(product),
                product_price: formatMoney(cheapestVariantPrice).toString(),
                product_status: hasStock ? 'In stock' : 'Out of stock',
                product_currency: 'GBP',
                product_categories: categoryFacetValues.join(','),
                product_brand: brand ?? '',
            };
            window.dmPt('track', viewedProduct);
        }
    }

    public trackActiveOrder(order: CartFragment | undefined) {
        const programID = environment.dotDigitalAbandonedCartProgramId;
        if (isPlatformBrowser(this.platformId) && programID) {
            if (!order) {
                return;
            }
            if (order.lines.length) {
                const mostRecentlyUpdatedLine = order.lines.reduce((mostRecent, line) => {
                    return mostRecent.updatedAt > line.updatedAt ? mostRecent : line;
                }, order.lines[0]);
                const fiveHours = 1000 * 60 * 60 * 5;
                const updatedAtTimestamp = new Date(mostRecentlyUpdatedLine.updatedAt).getTime();
                if (updatedAtTimestamp < Date.now() - fiveHours) {
                    // If the most recently-updated line is older than 5 hours, do not
                    // track the order. It means a returning customer has visited the
                    // site with stuff already in the cart and not yet added anything new.
                    return;
                }
            }
            const cart: DmptCart = {
                programID: programID,
                cartDelay: 60,
                cartID: order.id.toString(),
                cartPhase: 'ORDER_PENDING',
                currency: 'GBP',
                subtotal: formatMoney(order.subTotalWithTax),
                discountAmount: formatMoney(order.discounts.reduce((sum, d) => sum + d.amount, 0)),
                taxAmount: formatMoney(order.totalWithTax - order.total),
                grandTotal: formatMoney(order.totalWithTax),
                cartUrl: `https://www.artsupplies.co.uk/checkout?code=${order.code}`,
                lineItems: order.lines.map((line) => ({
                    sku: this.productSku(line.productVariant.product),
                    name: line.productVariant.name,
                    description: '',
                    category: '',
                    other: {},
                    unitPrice: formatMoney(line.unitPriceWithTax),
                    salePrice: formatMoney(line.unitPriceWithTax),
                    quantity: line.quantity,
                    totalPrice: formatMoney(line.linePriceWithTax),
                    imageUrl: line.featuredAsset.preview ?? '',
                    productUrl: `https://www.artsupplies.co.uk/p/${line.productVariant.product.slug}`,
                })),
            };
            if (environment.production) {
                window.dmPt('cartInsight', cart);
            }
        }
    }

    private identifyCustomer(emailAddress?: string) {
        if (emailAddress && isPlatformBrowser(this.platformId) && environment.production) {
            window.dmPt?.('identify', emailAddress);
        }
    }

    private productSku(product: { id: string }) {
        return `prod_${product.id}`;
    }
}
